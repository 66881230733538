export default [
    'YYYY-M-D',
    'YYYY-M-DD',
    'YYYY-MM-D',
    'YYYY-MM-DD',
    'M/D/YYYY',
    'MMMM D, YYYY',
    'MMMM D YYYY',
    'MMM D, YYYY',
    'MMM D YYYY'
];
