import './webapp-event-browser-component.css';
import _createUniqueIdsMixin from './create-unique-ids-mixin.js';
import _dataIoMixin from './data-io-mixin.js';
import _DatetimeInputComponent from './datetime-input-component.js';
import _later from 'isotropic-later';
import _MainComponent from './main-component.js';
import _moment from 'moment-timezone';
import _propertyListComponent from './property-list-component.js';
import _timeZone from '../js/time-zone.js';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    components: {
        'datetime-input': _DatetimeInputComponent,
        'main-component': _MainComponent,
        'property-list': _propertyListComponent
    },
    data () {
        return {
            columns: [
                'id',
                'name',
                'time',
                'userId',
                'username',
                'path',
                'resource',
                'resourceId',
                'statusCode',
                'data'
            ],
            filterId: '',
            filterName: '',
            filterPath: '',
            filterResource: '',
            filterResourceId: '',
            filterStatusCode: '',
            filterTimeAfter: '',
            filterTimeBefore: '',
            filterUserId: '',
            filterUsername: '',
            options: {
                filterable: [
                    'filterId',
                    'filterName',
                    'filterPath',
                    'filterResource',
                    'filterResourceId',
                    'filterStatusCode',
                    'filterTimeAfter',
                    'filterTimeBefore',
                    'filterUserId',
                    'filterUsername'
                ],
                filterByColumn: true,
                headings: {
                    data: 'Data',
                    id: 'Id',
                    name: 'Name',
                    path: 'Path',
                    resource: 'Resource',
                    resourceId: 'Resource Id',
                    statusCode: 'Status Code',
                    time: 'Time',
                    userId: 'User Id',
                    username: 'Username'
                },
                orderBy: {
                    ascending: false,
                    column: 'time'
                },
                perPage: 10,
                perPageValues: [
                    10,
                    50,
                    100,
                    250,
                    500,
                    1000
                ],
                preserveState: true,
                async requestFunction (request) {
                    const query = {
                        page: request.page,
                        pageSize: request.limit
                    };

                    if (request.orderBy) {
                        query[`sortBy[${request.orderBy}]`] = request.ascending ?
                            'asc' :
                            'desc';
                    }

                    if (request.multiSort) {
                        request.multiSort.forEach(sort => {
                            query[`sortBy[${sort.column}]`] = sort.ascending ?
                                'asc' :
                                'desc';
                        });
                    }

                    if (request.query.filterTimeAfter) {
                        if (request.query.filterTimeBefore) {
                            query.time = `${request.query.filterTimeAfter},${request.query.filterTimeBefore}`;
                        } else {
                            query.time = `${request.query.filterTimeAfter},`;
                        }
                    } else if (request.query.filterTimeBefore) {
                        query.time = `,${request.query.filterTimeBefore}`;
                    }

                    if (request.query.filterId) {
                        query.id = request.query.filterId;
                    }

                    if (request.query.filterName) {
                        query.name = request.query.filterName;
                    }

                    if (request.query.filterPath) {
                        query.path = request.query.filterPath;
                    }

                    if (request.query.filterResource) {
                        query.resource = request.query.filterResource;
                    }

                    if (request.query.filterResourceId) {
                        query.resourceId = request.query.filterResourceId;
                    }

                    if (request.query.filterStatusCode) {
                        query.statusCode = request.query.filterStatusCode;
                    }

                    if (request.query.filterUserId) {
                        query.userId = request.query.filterUserId;
                    }

                    if (request.query.filterUsername) {
                        query.username = request.query.filterUsername;
                    }

                    {
                        const {
                            count,
                            webappEventLogs
                        } = (await this.$parent.$parent.request({
                            json: true,
                            qs: query,
                            url: '/webappEventLog'
                        })).body;

                        return {
                            count,
                            data: webappEventLogs
                        };
                    }
                },
                serverMultiSorting: true,
                sortable: [
                    'id',
                    'name',
                    'time',
                    'userId',
                    'username',
                    'path',
                    'resource',
                    'resourceId',
                    'statusCode'
                ],
                sortIcon: {
                    base: 'fa',
                    down: 'fa-sort-down',
                    is: 'fa-sort',
                    up: 'fa-sort-up'
                },
                texts: {
                    count: 'Showing {from} to {to} of {count} user logs|{count} user logs|One user log',
                    limit: 'Page Size:',
                    noResults: 'No matching user logs'
                }
            },
            userColumnsDisplay: []
        };
    },
    filters: {
        formatTimestamp (value) {
            return _moment.tz(value, _timeZone).format('YYYY-MM-DD hh:mm A');
        }
    },
    methods: {
        onDatetimeFilterChange (filter) {
            _later.asap(() => {
                this.$refs.table.setFilter({
                    [filter]: this[filter]
                });
            });
        },
        onFilterChange (event) {
            const filter = event.target.name.substr(4),
                value = event.target.value;

            this[filter] = value;

            if (!event.target.validity.valid) {
                return;
            }

            this.$refs.table.setFilter({
                [filter]: value
            });
        },
        refresh () {
            this.$refs.table.refresh();
        },
        toggleColumn (column) {
            this.$refs.table.toggleColumn(column);
        }
    },
    mixins: [
        _createUniqueIdsMixin(),
        _dataIoMixin
    ],
    mounted () {
        this.$refs.table.userControlsColumns = true;
        this.$refs.table.userColumnsDisplay = this.userColumnsDisplay;

        /*  This is a weird hack because vue wasn't rendering the filter inputs
        in the correct places when the columns were all rendered together. */
        [
            'time',
            'username',
            'name',
            'path',
            'resource',
            'resourceId'
        ].forEach(column => {
            this.toggleColumn(column);
        });
    },
    name: 'webapp-event-browser',
    template: `
        <main-component class="webapp-event-browser">
            <h3>User Log Browser</h3>
            <v-server-table :columns="columns" name="userLogs" :options="options" ref="table" url="">

                <template slot="beforeLimit">
                    <div class="auto-width pure-menu pure-menu-horizontal">
                        <ul class="pure-menu-list">
                            <li class="pure-menu-allow-hover pure-menu-item pure-menu-has-children">
                                <button class="pure-button pure-menu-link" type="button">Columns</button>
                                <ul class="pure-menu-children">
                                    <li class="pure-menu-item" v-for="column in columns">
                                        <label class="auto-width pure-menu-link text-align-left" v-uni-for="'column-display-' + column">
                                            <input :checked="userColumnsDisplay.includes(column)" class="column-checkbox" :disabled="userColumnsDisplay.length === 1 && userColumnsDisplay.includes(column)" type="checkbox" v-on:click="toggleColumn(column)" v-uni-id="'column-display-' + column" />
                                            {{ options.headings[column] }}
                                        </label>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <button class="pure-button" type="button" v-on:click="refresh">
                        <i class="fa fa-refresh"></i>
                        Refresh User Logs
                    </button>
                </template>

                <template slot="time" slot-scope="props">{{
                    props.row.time | formatTimestamp
                }}</template>

                <!--<template slot="resourceId" slot-scope="props">
                    <template v-if="props.row.resourceExists">
                        <router-link v-if="props.row.resource === 'floodgateMenu'" :to="{name: 'floodgate_editMenu', params: {floodgateMenuId: props.row.resourceId}}" >{{props.row.resourceId}}</router-link>
                        <router-link v-else-if="props.row.resource === 'floodgateMessage'" :to="{name: 'floodgate_editMessage', params: {floodgateMessageId: props.row.resourceId}}" >{{props.row.resourceId}}</router-link>
                        <router-link v-else-if="props.row.resource === 'floodgateSchedule'" :to="{name: 'floodgate_editSchedule', params: {floodgateScheduleId: props.row.resourceId}}" >{{props.row.resourceId}}</router-link>
                        <router-link v-else-if="props.row.resource === 'userAudio'" :to="{name: 'audioRepository_editAudioFile', params: {userAudioId: props.row.resourceId}}" >{{props.row.resourceId}}</router-link>
                    </template>
                    <span title="Information for this resource is unavailable" v-else>{{props.row.resourceId}}</span>
                </template>-->

                <property-list class="font-x-small" :data="props.row.data" :expandUntilLength="12" slot="data" slot-scope="props" :sort="true" />

                <template slot="filter__time">
                    <datetime-input name="vf__filterTimeAfter" placeholder="Filter After" v-model="filterTimeAfter" v-on:input="onDatetimeFilterChange('filterTimeAfter')" />
                    <datetime-input name="vf__filterTimeBefore" placeholder="Filter Before" v-model="filterTimeBefore" v-on:input="onDatetimeFilterChange('filterTimeBefore')" />
                </template>

                <input class="wide" name="vf__filterId" pattern="^!?(?:(?:[0-9a-f\\-]+)|(?:=[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}))$$" placeholder="Filter Id" slot="filter__id" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterId" />

                <input class="wide" name="vf__filterName" placeholder="Filter Name" slot="filter__name" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterName" />
                <input class="wide" name="vf__filterPath" placeholder="Filter Path" slot="filter__path" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterPath" />

                <input class="wide" name="vf__filterResource" placeholder="Filter Resource" slot="filter__resource" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterResource" />

                <input class="wide" name="vf__filterResourceId" placeholder="Filter Resource Id" slot="filter__resourceId" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterResourceId" />

                <input class="wide" name="vf__filterStatusCode" placeholder="Filter Status Code" slot="filter__statusCode" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterStatusCode" />

                <input class="wide" name="vf__filterUserId" placeholder="Filter User Id" slot="filter__userId" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterUserId" />

                <input class="wide" name="vf__filterUsername" placeholder="Filter User Name" slot="filter__username" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterUsername" />

            </v-server-table>
        </main-component>
    `
});
