import './main-component.css';
import _MainMenuComponent from './main-menu-component.js';
import _mapGetters from './map-getters.js';
import _SpinnerComponent from './spinner-component.js';
import _traveliqMainLogo from './traveliq-main-logo.png';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    components: {
        'main-menu': _MainMenuComponent,
        spinner: _SpinnerComponent
    },
    computed: _mapGetters([
        'hasActiveRequest'
    ]),
    name: 'main-component',
    template: `
        <div class="main">
            <spinner v-if="hasActiveRequest" />
            <modals-container />
            <div class="justify-content">
                <img alt="Travel IQ" class="logo" src="${_traveliqMainLogo}" />
                <main-menu />
            </div>
            <hr />
            <slot />
        </div>
    `
});
