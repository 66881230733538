import _router from './router.js';
import _store from './store.js';
import _Vue from 'vue/dist/vue.esm.js';
import _VueModal from 'vue-js-modal';
import _VueTable from 'vue-tables-2/compiled/v-server-table.js';
import _VueTableTheme from './vue-table-theme.js';

_Vue.use(_VueModal, {
    dialog: true,
    dynamic: true
});
_Vue.use(_VueTable, {}, false, _VueTableTheme); // Note: vuex is disabled here
// Table multisort didn't work correctly with vuex

export default new _Vue({
    el: '#traveliq-management-webapp',
    router: _router,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return {
                selector: to.hash
            };
        }

        return {
            x: 0,
            y: 0
        };
    },
    store: _store
});
