import _audioTypeToMimeType from './audio-type-to-mime-type.js';
import _dataIoMixin from './data-io-mixin.js';
import _Vue from 'vue/dist/vue.esm.js';

const _preferredAudioFormat = Audio && (() => {
        const audioElement = new Audio();

        if (audioElement.canPlayType) {
            return [
                'weba',
                'opus',
                'vorbis',
                'm4a',
                'mp3',
                'wav'
            ].find(audioFormat => audioElement.canPlayType(_audioTypeToMimeType[audioFormat]));
        }
    })(),
    _preferredAudioMimeType = _audioTypeToMimeType[_preferredAudioFormat];

export default _Vue.extend({
    computed: {
        url () {
            if (typeof this.src === 'object') {
                return this.src[_preferredAudioFormat];
            }

            return `${this.src}.${_preferredAudioFormat}`;
        }
    },
    data () {
        return {
            audioElement: null,
            isLoading: false,
            isPlaying: false
        };
    },
    methods: {
        async onClick () {
            if (this.audioElement) {
                this.audioElement.pause();
                this.audioElement = null;
                this.isPlaying = false;
                return;
            }

            if (this.url) {
                this.isLoading = true;

                this.audioElement = new Audio(URL.createObjectURL(new Blob([
                    (await this.request({
                        encoding: null,
                        url: this.url
                    })).body
                ], {
                    type: _preferredAudioMimeType
                })));

                this.isLoading = false;

                const promise = this.audioElement.play();

                if (promise) {
                    promise.catch(() => {
                        this.audioElement = null;
                        this.isPlaying = false;
                    });
                }

                this.audioElement.addEventListener('ended', () => {
                    this.audioElement = null;
                    this.isPlaying = false;
                });

                this.isPlaying = true;
            }
        }
    },
    mixins: [
        _dataIoMixin
    ],
    name: 'audio-playback-icon',
    props: {
        src: {
            required: true
        },
        title: {
            default: 'Listen to this audio.',
            type: String
        }
    },
    template: `
        <i :class="isLoading ? 'audio-playback-icon fa fa-spinner' : isPlaying ? 'audio-playback-icon fa fa-stop pointer' : 'audio-playback-icon fa fa-volume-up pointer'" :title="title" v-on:click="onClick"></i>
    `
});
