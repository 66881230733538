/* import _AudioRepositoryComponent from './audio-repository-component.js';
import _AudioRepositoryEditComponent from './audio-repository-edit-component.js';
import _FloodgateEditMenuComponent from './floodgate-edit-menu-component.js';
import _FloodgateEditMessageComponent from './floodgate-edit-message-component.js';
import _FloodgateEditScheduleComponent from './floodgate-edit-schedule-component.js';
import _FloodgateMenusComponent from './floodgate-menus-component.js';
import _FloodgateMessagesComponent from './floodgate-messages-component.js';
import _FloodgateSchedulesComponent from './floodgate-schedules-component.js';*/
import _HomeComponent from './home-component.js';
import _ReportsComponent from './reports-component.js';
import _store from './store.js';
// import _TransferDestinationsComponent from './transfer-destinations-component.js';
import _UsersComponent from './users-component.js';
import _Vue from 'vue/dist/vue.esm.js';
import _VueRouter from 'vue-router';
import _VuiLogBrowserComponent from './vui-log-browser-component.js';
import _VuiLogEventBrowserComponent from './vui-log-event-browser-component.js';
import _WebappEventBrowserComponent from './webapp-event-browser-component.js';

_Vue.use(_VueRouter);

const _router = new _VueRouter({
    mode: 'history',
    routes: [{
        caseSensitive: true,
        component: _HomeComponent,
        path: '/'
    /* }, {
        caseSensitive: true,
        component: _AudioRepositoryComponent,
        path: '/audioRepository'
    }, {
        caseSensitive: true,
        component: _AudioRepositoryEditComponent,
        name: 'audioRepository_editAudioFile',
        path: '/audioRepository/editAudioFile/:userAudioId'
    }, {
        caseSensitive: true,
        component: _AudioRepositoryEditComponent,
        path: '/audioRepository/newAudioFile'
    }, {
        caseSensitive: true,
        path: '/floodgates',
        redirect: '/floodgates/currentSchedules'
    }, {
        caseSensitive: true,
        component: _FloodgateSchedulesComponent,
        path: '/floodgates/currentSchedules',
        props: {
            status: 'current'
        }
    }, {
        caseSensitive: true,
        component: _FloodgateEditMenuComponent,
        name: 'floodgate_editMenu',
        path: '/floodgates/editMenu/:floodgateMenuId',
        props: {
            type: 'menu'
        }
    }, {
        caseSensitive: true,
        component: _FloodgateEditMessageComponent,
        name: 'floodgate_editMessage',
        path: '/floodgates/editMessage/:floodgateMessageId',
        props: {
            type: 'message'
        }
    }, {
        caseSensitive: true,
        component: _FloodgateEditScheduleComponent,
        name: 'floodgate_editSchedule',
        path: '/floodgates/editSchedule/:floodgateScheduleId'
    }, {
        caseSensitive: true,
        component: _FloodgateSchedulesComponent,
        path: '/floodgates/futureSchedules',
        props: {
            status: 'future'
        }
    }, {
        caseSensitive: true,
        component: _FloodgateMenusComponent,
        path: '/floodgates/menus'
    }, {
        caseSensitive: true,
        component: _FloodgateMessagesComponent,
        path: '/floodgates/messages'
    }, {
        caseSensitive: true,
        component: _FloodgateEditMenuComponent,
        path: '/floodgates/newMenu',
        props: {
            type: 'menu'
        }
    }, {
        caseSensitive: true,
        component: _FloodgateEditMessageComponent,
        path: '/floodgates/newMessage',
        props: {
            type: 'message'
        }
    }, {
        caseSensitive: true,
        component: _FloodgateEditScheduleComponent,
        path: '/floodgates/newSchedule'
    }, {
        caseSensitive: true,
        component: _FloodgateSchedulesComponent,
        path: '/floodgates/pastSchedules',
        props: {
            status: 'past'
        }
    */}, {
        async beforeEnter () {
            await _store.dispatch('onNavigate', {
                event: 'logOut'
            });
            await _store.dispatch('logOut');
        },
        caseSensitive: true,
        path: '/logOut'
    }, {
        caseSensitive: true,
        component: _ReportsComponent,
        path: '/reports'
    /* }, {
        caseSensitive: true,
        component: _TransferDestinationsComponent,
        path: '/transferDestinations'
    */
    }, {
        caseSensitive: true,
        component: _UsersComponent,
        path: '/users'
    }, {
        caseSensitive: true,
        component: _UsersComponent,
        path: '/users/:userId'
    }, {
        caseSensitive: true,
        component: _VuiLogBrowserComponent,
        path: '/vuiLogBrowser'
    }, {
        beforeEnter (to, from, next) {
            if (!_store.state.config.enableWebAppEventApi) {
                return next('/');
            }

            next();
        },
        caseSensitive: true,
        component: _WebappEventBrowserComponent,
        path: '/userLogBrowser'
    }, {
        caseSensitive: true,
        component: _VuiLogEventBrowserComponent,
        path: '/vuiLogBrowser/:vuiId'
    }, {
        caseSensitive: true,
        path: '*',
        redirect: '/'
    }]
});

_router.beforeEach(async (to, from, next) => {
    // record navigation event.
    try {
        await _store.dispatch('onNavigate', {
            event: 'navigate',
            location: to.path
        });
    } catch (error) {
        await _store.dispatch('logOut');
        return;
    }

    next();
});

export default _router;
