import './spinner-component.css';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    name: 'spinner',
    template: `
        <div class="spinner">
            <div class="spinner-box">
                <div class="spinner-a">
                    <div>
                    </div>
                </div>
                <div class="spinner-b">
                    <div>
                    </div>
                </div>
                <div class="spinner-c">
                    <div>
                    </div>
                </div>
                <div class="spinner-d">
                    <div>
                    </div>
                </div>
                <div class="spinner-e">
                    <div>
                    </div>
                </div>
                <div class="spinner-f">
                    <div>
                    </div>
                </div>
                <div class="spinner-g">
                    <div>
                    </div>
                </div>
                <div class="spinner-h">
                    <div>
                    </div>
                </div>
                <div class="spinner-i">
                    <div>
                    </div>
                </div>
                <div class="spinner-j">
                    <div>
                    </div>
                </div>
                <div class="spinner-k">
                    <div>
                    </div>
                </div>
                <div class="spinner-l">
                    <div>
                    </div>
                </div>
                <div class="spinner-m">
                    <div>
                    </div>
                </div>
            </div>
        </div>
    `
});
