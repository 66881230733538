import './property-list-component.css';
import _naturalSort from 'isotropic-natural-sort';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    computed: {
        itemCount () {
            return this.data ?
                (
                    Array.isArray(this.data) ?
                        this.data :
                        Object.keys(this.data)
                ).length :
                0;
        },
        shouldExpand () {
            return typeof this.manualExpand === 'boolean' ?
                this.manualExpand :
                this.itemCount <= this.expandUntilLength;
        },
        sortedData () {
            if (this.data && this.sort) {
                if (Array.isArray(this.data)) {
                    return this.data.slice().sort(_naturalSort());
                }

                return Object.keys(this.data).sort(_naturalSort()).reduce((sortedData, key) => {
                    sortedData[key] = this.data[key];
                    return sortedData;
                }, {});
            }

            return this.data;
        }
    },
    data () {
        return {
            manualExpand: null
        };
    },
    methods: {
        expand () {
            this.manualExpand = true;
        }
    },
    name: 'property-list',
    props: {
        data: {},
        expandUntilLength: {
            default: Infinity
        },
        sort: {
            default: false
        }
    },
    template: `
        <table class="property-list pure-table pure-table-striped" v-if="this.data">
            <tbody>
                <template v-if="this.shouldExpand">
                    <template v-if="Array.isArray(this.data)">
                        <tr v-for="value in this.sortedData">
                            <td>
                                <property-list :data="value" :expandUntilLength="expandUntilLength" :sort="sort" v-if="typeof value === 'object'" />
                                <span v-else>{{ value }}</span>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="(value, key) in this.sortedData">
                            <td>{{ key }}</td>
                            <td>
                                <property-list :data="value" :expandUntilLength="expandUntilLength" :sort="sort" v-if="typeof value === 'object'" />
                                <span v-else>{{ value }}</span>
                            </td>
                        </tr>
                    </template>
                </template>
                <tr v-else>
                    <td>
                        {{ this.itemCount }} items
                        <button class="pure-button" type="button" v-on:click="expand">
                            Click to expand
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    `
});
