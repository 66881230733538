import './dialog-mixin.css';

export default {
    methods: {
        alert (props, config) {
            if (typeof props === 'string') {
                props = {
                    text: props
                };
            }

            return new Promise(resolve => {
                this.$modal.show({
                    destroyed () {
                        window.removeEventListener('keydown', this.onKeyDown);
                        resolve();
                    },
                    methods: {
                        onKeyDown (event) {
                            switch (event.which) {
                                case 13: // enter
                                case 27: // esc
                                    this.$emit('close');
                            }
                        }
                    },
                    mounted () {
                        window.addEventListener('keydown', this.onKeyDown);
                    },
                    props: [
                        'text',
                        'title'
                    ],
                    template: `
                        <article class="dialog">
                            <header>{{
                                title
                            }}</header>
                            <section>{{
                                text
                            }}</section>
                            <footer class="text-align-center">
                                <button class="pure-button pure-button-primary" type="button" v-on:click="$emit('close')">
                                    Okay
                                </button>
                            </footer>
                        </article>
                    `
                }, {
                    text: '',
                    title: 'Alert',
                    ...props
                }, {
                    adaptive: true,
                    clickToClose: true,
                    height: 'auto',
                    transition: 'fade',
                    ...config
                });
            });
        },
        confirm (props, config) {
            if (typeof props === 'string') {
                props = {
                    text: props
                };
            }

            return new Promise(resolve => {
                this.$modal.show({
                    data () {
                        return {
                            confirmed: false
                        };
                    },
                    destroyed () {
                        window.removeEventListener('keydown', this.onKeyDown);
                        resolve(this.confirmed);
                    },
                    methods: {
                        onConfirm () {
                            this.confirmed = true;
                            this.$emit('close');
                        },
                        onKeyDown (event) {
                            switch (event.which) {
                                case 13: // enter
                                case 27: // esc
                                    this.$emit('close');
                            }
                        }
                    },
                    mounted () {
                        window.addEventListener('keydown', this.onKeyDown);
                    },
                    props: [
                        'text',
                        'title'
                    ],
                    template: `
                        <article class="dialog">
                            <header>{{
                                title
                            }}</header>
                            <section>{{
                                text
                            }}</section>
                            <footer>
                                <button class="pure-button pure-button-primary" type="button" v-on:click="$emit('close')">
                                    No
                                </button>
                                <button class="pure-button" type="button" v-on:click="onConfirm">
                                    Yes
                                </button>
                            </footer>
                        </article>
                    `
                }, {
                    text: '',
                    title: 'Confirm',
                    ...props
                }, {
                    adaptive: true,
                    clickToClose: true,
                    height: 'auto',
                    transition: 'fade',
                    ...config
                });
            });
        },
        prompt (props, config) {
            if (typeof props === 'string') {
                props = {
                    text: props
                };
            }

            return new Promise(resolve => {
                this.$modal.show({
                    data () {
                        return {
                            input: this.value,
                            submitted: false
                        };
                    },
                    destroyed () {
                        window.removeEventListener('keydown', this.onKeyDown);
                        resolve(
                            this.submitted ?
                                this.input :
                                null
                        );
                    },
                    methods: {
                        onKeyDown (event) {
                            switch (event.which) {
                                case 27: // esc
                                    this.$emit('close');
                            }
                        },
                        onSubmit () {
                            this.submitted = true;
                            this.$emit('close');
                        }
                    },
                    mounted () {
                        window.addEventListener('keydown', this.onKeyDown);
                        this.$refs.input.focus();
                        if (this.select) {
                            this.$refs.input.select();
                        }
                    },
                    props: [
                        'select',
                        'text',
                        'title',
                        'value'
                    ],
                    template: `
                        <article class="dialog">
                            <header>{{
                                title
                            }}</header>
                            <section>{{
                                text
                            }}</section>
                            <form class="pure-form" v-on:submit.prevent="onSubmit">
                                <fieldset>
                                    <input class="wide" ref="input" type="text" v-model="input" />
                                </fieldset>
                                <footer>
                                    <button class="pure-button" type="button" v-on:click="$emit('close')">
                                        Cancel
                                    </button>
                                    <button class="pure-button pure-button-primary" type="submit">
                                        Save
                                    </button>
                                </footer>
                            </form>
                        </article>
                    `
                }, {
                    select: true,
                    text: '',
                    title: 'Prompt',
                    value: '',
                    ...props
                }, {
                    adaptive: true,
                    clickToClose: true,
                    height: 'auto',
                    transition: 'fade',
                    ...config
                });
            });
        }
    }
};
