import './reports-component.css';
import _createUniqueIdsMixin from './create-unique-ids-mixin.js';
import _dataIoMixin from './data-io-mixin.js';
import _DatetimeInputComponent from './datetime-input-component.js';
import _dialogMixin from './dialog-mixin.js';
import _later from 'isotropic-later';
import _MainComponent from './main-component.js';
import _moment from 'moment-timezone';
import _querystring from 'querystring';
import _timeZone from '../js/time-zone.js';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    components: {
        'datetime-input': _DatetimeInputComponent,
        'main-component': _MainComponent
    },
    computed: {
        reportSectionCount () {
            return Object.keys(this.reportSections).reduce((reportSectionCount, category) => reportSectionCount + this.reportSections[category].length + 1, 0);
        },
        reportUrl () {
            return `/report?${
                _querystring.stringify({
                    beginTime: this.beginTime,
                    endTime: this.endTime,
                    reportSection: this.selectedReportSections
                })
            }`;
        },
        selectedReportSectionCount () {
            return this.selectedReportSections.length;
        },
        timeRangeIsRecent () {
            return this.endTime && _moment.tz(_timeZone).subtract(4, 'hours').isBefore(this.endTime);
        }
    },
    data () {
        return {
            beginTime: _moment.tz(_timeZone).subtract(1, 'day').startOf('day').toISOString(),
            endTime: _moment.tz(_timeZone).startOf('day').toISOString(),
            reportSections: {},
            selectedReportSections: [],
            timeChangeHandled: false,
            timeRange: 'yesterday'
        };
    },
    methods: {
        generateReport () {
            window.open(this.reportUrl, '_blank');
        },
        onValueChange (event) {
            const name = event.target.name;

            switch (name) {
                case 'timeRange': {
                    const value = event.target.value;

                    switch (value) {
                        case 'lastMonth':
                            this.beginTime = _moment.tz(_timeZone).subtract(1, 'month').startOf('month').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('month').toISOString();
                            break;
                        case 'lastNineMonths':
                            this.beginTime = _moment.tz(_timeZone).subtract(9, 'months').startOf('month').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('month').toISOString();
                            break;
                        case 'lastNinetyDays':
                            this.beginTime = _moment.tz(_timeZone).subtract(90, 'days').startOf('day').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('day').toISOString();
                            break;
                        case 'lastSevenDays':
                            this.beginTime = _moment.tz(_timeZone).subtract(7, 'days').startOf('day').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('day').toISOString();
                            break;
                        case 'lastSixMonths':
                            this.beginTime = _moment.tz(_timeZone).subtract(6, 'months').startOf('month').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('month').toISOString();
                            break;
                        case 'lastThirtyDays':
                            this.beginTime = _moment.tz(_timeZone).subtract(30, 'days').startOf('day').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('day').toISOString();
                            break;
                        case 'lastThreeMonths':
                            this.beginTime = _moment.tz(_timeZone).subtract(3, 'months').startOf('month').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('month').toISOString();
                            break;
                        case 'lastYear':
                            this.beginTime = _moment.tz(_timeZone).subtract(1, 'year').startOf('year').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('year').toISOString();
                            break;
                        case 'yesterday':
                            this.beginTime = _moment.tz(_timeZone).subtract(1, 'day').startOf('day').toISOString();
                            this.endTime = _moment.tz(_timeZone).startOf('day').toISOString();
                            break;
                    }

                    this.timeRange = value;
                    return;
                }
            }

            this[name] = event.target.value;
        },
        selectAll () {
            this.selectedReportSections = Object.keys(this.reportSections).reduce((selectedReportSections, category) => {
                this.reportSections[category].forEach(reportSection => {
                    selectedReportSections.push(reportSection.id);
                });

                return selectedReportSections;
            }, []);
        },
        selectNone () {
            this.selectedReportSections = [];
        }
    },
    mixins: [
        _createUniqueIdsMixin(),
        _dataIoMixin,
        _dialogMixin
    ],
    async mounted () {
        this.reportSections = await this.fetchReportSections({
            beginTime: this.beginTime,
            endTime: this.endTime
        });
    },
    name: 'reports',
    template: `
        <main-component class="reports">
            <h3>
                Reports
            </h3>
            <form class="pure-form" v-on:submit.prevent>
                <fieldset>
                    <div class="pure-form-aligned">
                        <div class="pure-control-group">
                            <label v-uni-for="'reportsTimeRange'">
                                Time Range
                            </label>
                            <select key="timeRange" name="timeRange" required="required" v-on:change="onValueChange" v-uni-id="'reportsTimeRange'" :value="timeRange">
                                <option value="custom">
                                    Custom
                                </option>
                                <option value="yesterday">
                                    Yesterday
                                </option>
                                <option value="lastSevenDays">
                                    Last Seven Days
                                </option>
                                <option value="lastThirtyDays">
                                    Last Thirty Days
                                </option>
                                <option value="lastNinetyDays">
                                    Last Ninety Days
                                </option>
                                <option value="lastMonth">
                                    Last Month
                                </option>
                                <option value="lastThreeMonths">
                                    Last Three Months
                                </option>
                                <option value="lastSixMonths">
                                    Last Six Months
                                </option>
                                <option value="lastNineMonths">
                                    Last Nine Months
                                </option>
                                <option value="lastYear">
                                    Last Year
                                </option>
                            </select>
                        </div>
                        <template v-if="timeRange === 'custom'">
                            <div class="date-range pure-control-group">
                                <label v-uni-for="'reportsBeginTime'">
                                    Begin Date / Time*
                                </label>
                                <datetime-input name="beginTime" v-model="beginTime" v-uni-id="'reportsBeginTime'" />
                            </div>
                            <div class="date-range pure-control-group">
                                <label v-uni-for="'reportsEndTime'">
                                    End Date / Time*
                                </label>
                                <datetime-input name="endTime" v-model="endTime" v-uni-id="'reportsEndTime'" />
                            </div>
                            <p class="warning">
                                * Begin Time and End Time should be Tallahassee local time.
                            </p>
                            <p class="warning">
                                * Begin Time is inclusive while end time is exclusive, meaning the report will run with data including the begin date time right up to but not including the end time.
                            </p>
                            <p class="warning">
                                * For instance, if you selected 2018-01-01 12:00 AM through 2018-01-02 12:00 AM, the data returned will be for 2018-01-01 12:00 AM through 2018-01-01 11:59:59.999 PM.  Right up till, but not including 2018-01-02 12:00 AM.
                            </p>
                        </template>
                        <p class="warning" v-if="timeRangeIsRecent">
                            * Reports for very recent time ranges may not be accurate as the logging systems are still working to archive the logs.
                        </p>
                    </div>
                    <div class="justify-content pure-form-stacked" v-if="reportSectionCount">
                        <div class="pure-control-group">
                            <label v-uni-for="'reportsReportSections'">
                                Report Sections
                            </label>
                            <select class="auto-width" key="reportsReportSections" multiple="multiple" :size="reportSectionCount" v-model="selectedReportSections" v-uni-id="'reportsReportSections'">
                                <optgroup :label="category" v-for="(categoryArray, category) in reportSections">
                                    <option v-for="reportSection in categoryArray" :value="reportSection.id">{{
                                        reportSection.name
                                    }}</option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="pure-control-group">
                            <div>
                                <button class="pure-button wide" type="button" v-on:click="selectAll">
                                    <i class="fa fa-check-square-o"></i>
                                    Select All
                                </button>
                            </div>
                            <div>
                                <a :href="reportUrl" rel="noopener" target="_blank" v-if="selectedReportSectionCount" v-on:click="generateReport">
                                    <button class="pure-button pure-button-primary wide" type="button">
                                        Generate Report
                                    </button>
                                </a>
                                <button class="pure-button pure-button-disabled wide" type="button" v-else>
                                    Generate Report
                                </button>
                            </div>
                        </div>
                        <div class="wide">
                        </div>
                    </div>
                </fieldset>
            </form>
        </main-component>
    `,
    watch: {
        beginTime () {
            if (this.timeChangeHandled) {
                return;
            }

            this.timeChangeHandled = true;

            _later.asap(async () => {
                this.timeChangeHandled = false;

                if (this.beginTime && this.endTime) {
                    if (_moment(this.endTime).isSameOrBefore(this.beginTime)) {
                        this.alert('The end time can not be before the begin time.');
                        this.reportSections = {};
                        this.selectedReportSections = [];
                        return;
                    }

                    this.reportSections = await this.fetchReportSections({
                        beginTime: this.beginTime,
                        endTime: this.endTime
                    });
                } else {
                    this.reportSections = [];
                }

                this.selectedReportSections = [];
            });
        },
        endTime () {
            if (this.timeChangeHandled) {
                return;
            }

            this.timeChangeHandled = true;

            _later.asap(async () => {
                this.timeChangeHandled = false;

                if (this.beginTime && this.endTime) {
                    if (_moment(this.endTime).isSameOrBefore(this.beginTime)) {
                        this.alert('The end time can not be before the begin time.');
                        this.reportSections = {};
                        this.selectedReportSections = [];
                        return;
                    }

                    this.reportSections = await this.fetchReportSections({
                        beginTime: this.beginTime,
                        endTime: this.endTime
                    });
                } else {
                    this.reportSections = [];
                }

                this.selectedReportSections = [];
            });
        }
    }
});
