import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    computed: {
        enableWebAppEventApi () {
            return this.$store.state.config.enableWebAppEventApi;
        }
    },
    name: 'main-menu',
    template: `
        <nav class="main-menu pure-menu pure-menu-horizontal text-align-right">
            <ul class="pure-menu-list">
                <li class="pure-menu-item">
                    <router-link class="pure-menu-link" exact to="/">
                        Home
                    </router-link>
                </li>
                <!--<li class="pure-menu-item">
                    <router-link class="pure-menu-link" to="/audioRepository">
                        Audio Repository
                    </router-link>
                </li>
                <li class="pure-menu-item">
                    <router-link class="pure-menu-link" to="/floodgates">
                        Floodgates
                    </router-link>
                </li>-->
                <li class="pure-menu-item">
                    <router-link class="pure-menu-link" to="/reports">
                        Reports
                    </router-link>
                </li>
                <!--<li class="pure-menu-item">
                    <router-link class="pure-menu-link" to="/transferDestinations">
                        Transfer Destinations
                    </router-link>
                </li>-->
                <li v-if="enableWebAppEventApi" class="pure-menu-item">
                    <router-link class="pure-menu-link" to="/userLogBrowser">
                        User Logs
                    </router-link>
                </li>
                <li class="pure-menu-item">
                    <router-link class="pure-menu-link" to="/users">
                        Users
                    </router-link>
                </li>
                <li class="pure-menu-item">
                    <router-link class="pure-menu-link" to="/vuiLogBrowser">
                        VUI Logs
                    </router-link>
                </li>
                <li class="pure-menu-item">
                    <router-link class="pure-menu-link" to="/logOut">
                        Log Out
                    </router-link>
                </li>
            </ul>
        </nav>
    `
});
