export default [
    'H:m:s.SSSS',
    'h:m:s.SSSSA',
    'h:m:s.SSSSa',
    'h:m:s.SSSS A',
    'h:m:s.SSSS a',
    'H:m:s',
    'h:m:sA',
    'h:mmA',
    'h:mma',
    'h:mm A',
    'h:mm a',
    'hh:mmA',
    'hh:mma',
    'hh:mm A',
    'hh:mm a',
    'h:m:sa',
    'h:m:s A',
    'h:m:s a',
    'H:m',
    'h:mA',
    'h:ma',
    'h:m A',
    'h:m a',
    'H',
    'hA',
    'ha',
    'h A',
    'h a'
];
