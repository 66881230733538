import './users-component.css';
import * as _http from 'https';
import _MainComponent from './main-component.js';
import _moment from 'moment-timezone';
import _timeZone from '../js/time-zone.js';
import _Vue from 'vue/dist/vue.esm.js';
import _VueTable from 'vue-tables-2/compiled/v-client-table.js';
import _VueTableTheme from './vue-table-theme.js';

_Vue.use(_VueTable, {}, false, _VueTableTheme);

const _customBoolSort = (ascending, a, b, propName) => {
        const value1 = a[propName] === 'Yes' ?
                2 :
                a[propName] === 'Pending' ?
                    1 :
                    0,
            value2 = b[propName] === 'Yes' ?
                2 :
                b[propName] === 'Pending' ?
                    1 :
                    0;

        if (ascending) {
            return value1 - value2;
        }

        return value2 - value1;
    },
    _customCharSort = (ascending, a, b, propName) => {
        const name1 = a[propName]?.toLowerCase(),
            name2 = b[propName]?.toLowerCase();

        if (ascending) {
            return name1?.localeCompare(name2);
        }

        return name2?.localeCompare(name1);
    };

export default _Vue.extend({
    components: {
        'main-component': _MainComponent
    },
    async created () {
        let users = [];

        _http.get(`${window.location.origin}/user`, res => {
            const data = [];

            res.on('data', chunk => {
                data.push(chunk);
            });
            res.on('end', () => {
                // eslint-disable-next-line no-undef -- because Buffer is defined by node by default
                users = JSON.parse(Buffer.concat(data).toString());

                {
                    const response = users.totalUsers?.map(user => {
                        {
                            let email,
                                verified = null;

                            user.Attributes.forEach(atr => {
                                if (atr.Name === 'email_verified') {
                                    verified = atr.Value === 'true' ?
                                        'Yes' :
                                        'No';
                                }

                                if (atr.Name === 'email') {
                                    email = atr.Value;
                                }

                                return null;
                            });

                            return {
                                createdDate: _moment.tz(user.UserCreateDate, _timeZone).format('YYYY-MM-DD hh:mm A'),
                                email,
                                enabled: user.Enabled ?
                                    'Yes' :
                                    'No',
                                status: this.snakeCaseToCapitalCase(user.UserStatus),
                                verified: verified ?
                                    verified :
                                    'Pending'
                            };
                        }
                    });

                    this.tableData = response || [];
                }
            });
        });
    },
    data () {
        return {
            columns: [
                'email',
                'createdDate',
                'status',
                'verified',
                'enabled'
            ],
            email: '',
            options: {
                customSorting: {
                    createdDate: this.customDateSort,
                    email: this.customEmailSort,
                    enabled: this.customEnabledSort,
                    status: this.customStatusSort,
                    verified: this.customVerifiedSort
                },
                filterable: [
                    'email'
                ],
                filterByColumn: true,
                headings: {
                    createdDate: 'Created Date',
                    email: 'Email',
                    enabled: 'Enabled',
                    status: 'Status',
                    verified: 'Email Verified'
                },
                preserveState: true,
                sortable: ['createdDate', 'email', 'enabled', 'status', 'verified'],
                sortIcon: {
                    base: 'fa',
                    down: 'fa-sort-down',
                    is: 'fa-sort',
                    up: 'fa-sort-up'
                },
                texts: {
                    count: 'Showing {from} to {to} of {count} Users|{count} Users|One User',
                    limit: 'Page Size:',
                    noResults: 'No matching Users'
                }
            },
            tableData: [],
            userColumnsDisplay: []
        };
    },
    methods: {
        customDateSort (ascending) {
            return (a, b) => {
                const date1 = a?.createdDate,
                    date2 = b?.createdDate,
                    dateObj1 = new Date(date1),
                    dateObj2 = new Date(date2);

                if (ascending) {
                    return dateObj1 - dateObj2;
                }

                return dateObj2 - dateObj1;
            };
        },
        customEmailSort (ascending) {
            return (a, b) => _customCharSort(ascending, a, b, 'email');
        },
        customEnabledSort (ascending) {
            return (a, b) => _customBoolSort(ascending, a, b, 'enabled');
        },
        customStatusSort (ascending) {
            return (a, b) => _customCharSort(ascending, a, b, 'status');
        },
        customVerifiedSort (ascending) {
            return (a, b) => _customBoolSort(ascending, a, b, 'verified');
        },
        onFilterChange (event) {
            const filter = event.target.name.substr(4),
                value = event.target.value;

            this[filter] = value;

            if (!event.target.validity.valid) {
                return;
            }

            this.$refs.table.setFilter({
                [filter]: value
            });
        },
        refresh () {
            this.$refs.table.refresh();
        },
        snakeCaseToCapitalCase (input) {
            const groupWords = input.split('_'),
                setupWords = groupWords.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()),
                wordsResult = setupWords.join(' ');

            return wordsResult;
        }
    },
    name: 'users',
    template: `
        <main-component class="users">
            <h3>Users</h3>
            <v-client-table :data="tableData" :columns="columns" :options="options"></v-client-table>
         </main-component>
    `
});
