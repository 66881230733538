import _MainComponent from './main-component.js';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    components: {
        'main-component': _MainComponent
    },
    name: 'home',
    template: `
        <main-component class="home">
            <div>
            </div>
        </main-component>
    `
});
