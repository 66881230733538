import _Error from 'isotropic-error';
import _RequestThrottler from '@traveliq-ivr-lib/request-throttler';
import _Vue from 'vue/dist/vue.esm.js';
import _Vuex from 'vuex';

_Vue.use(_Vuex);

const _origin = window.location.origin,
    _requestThrottler = _RequestThrottler(),
    _store = new _Vuex.Store({
        actions: {
            logOut () {
                window.location.replace('/logOut');
            },
            async onNavigate ({
                commit,
                dispatch
            }, {
                event,
                location
            }) {
                const config = await dispatch('request', {
                    json: true,
                    qs: {
                        event,
                        location
                    },
                    url: '/config'
                })
                    .then(response => response.body.config)
                    .catch(error => {
                        throw _Error({
                            error,
                            message: 'Error fetching config'
                        });
                    });

                if (config) {
                    commit('setConfig', config);
                } else {
                    throw _Error({
                        message: 'Error fetching config'
                    });
                }

                commit('setPreviousPathname', window.location.pathname);
            },
            async refreshFloodgateGroups ({
                commit,
                dispatch
            }) {
                try {
                    commit('setFloodgateGroups', (await dispatch('request', {
                        json: true,
                        url: '/floodgateGroup'
                    })).body.floodgateGroups);
                } catch (error) {
                    throw _Error({
                        error,
                        message: 'Error refreshing floodgate groups'
                    });
                }
            },
            async refreshFloodgateMenus ({
                commit,
                dispatch
            }) {
                try {
                    commit('setFloodgateMenus', (await dispatch('request', {
                        json: true,
                        qs: {
                            'sortBy[name]': 'asc'
                        },
                        url: '/floodgateMenu'
                    })).body.floodgateMenus);
                } catch (error) {
                    throw _Error({
                        error,
                        message: 'Error refreshing floodgate menus'
                    });
                }
            },
            async refreshFloodgateMessages ({
                commit,
                dispatch
            }) {
                try {
                    commit('setFloodgateMessages', (await dispatch('request', {
                        json: true,
                        qs: {
                            'sortBy[name]': 'asc'
                        },
                        url: '/floodgateMessage'
                    })).body.floodgateMessages);
                } catch (error) {
                    throw _Error({
                        error,
                        message: 'Error refreshing floodgate messages'
                    });
                }
            },
            async refreshTransferDestinations ({
                commit,
                dispatch
            }) {
                try {
                    commit('setTransferDestinations', (await dispatch('request', {
                        json: true,
                        qs: {
                            'sortBy[name]': 'asc'
                        },
                        url: '/transferDestination'
                    })).body.transferDestinations);
                } catch (error) {
                    throw _Error({
                        error,
                        message: 'Error refreshing transfer destinations'
                    });
                }
            },
            async refreshUserAudios ({
                commit,
                dispatch
            }) {
                try {
                    commit('setUserAudios', (await dispatch('request', {
                        json: true,
                        qs: {
                            'sortBy[name]': 'asc'
                        },
                        url: '/userAudio'
                    })).body.userAudios);
                } catch (error) {
                    throw _Error({
                        error,
                        message: 'Error refreshing user audios'
                    });
                }
            },
            request ({
                commit
            }, config) {
                commit('incrementActiveRequestCount');

                return _requestThrottler.request({
                    baseUrl: _origin,
                    ...config
                })
                    .then(response => {
                        if (response.statusCode === 403) {
                            // TODO
                        }

                        if (response.statusCode < 400) {
                            return response;
                        }
                    })
                    .catch(error => {
                        throw _Error({
                            details: {
                                config,
                                ...error.details
                            },
                            error,
                            message: 'Error making request'
                        });
                    })
                    .finally(() => {
                        commit('decrementActiveRequestCount');
                    });
            }
        },
        getters: {
            floodgateByTypeAndId (state) {
                return state.floodgateByTypeAndId;
            },
            floodgateGroups (state) {
                return state.floodgateGroups;
            },
            floodgateMenus (state) {
                return state.floodgateMenus;
            },
            floodgateMessages (state) {
                return state.floodgateMessages;
            },
            hasActiveRequest (state) {
                return state.activeRequestCount > 0;
            },
            transferDestinations (state) {
                return state.transferDestinations;
            },
            userAudios (state) {
                return state.userAudios;
            }
        },
        mutations: {
            decrementActiveRequestCount (state) {
                state.activeRequestCount = Math.max(state.activeRequestCount - 1, 0);
            },
            incrementActiveRequestCount (state) {
                state.activeRequestCount += 1;
            },
            setConfig (state, config) {
                if (window.localStorage) {
                    if (config) {
                        window.localStorage.setItem('config', config);
                    } else {
                        window.localStorage.removeItem('config');
                    }
                }

                state.config = config;
            },
            setFloodgateGroups (state, floodgateGroups) {
                state.floodgateGroups = floodgateGroups;

                const floodgateByTypeAndId = {},
                    processFloodgateSubgroup = ({
                        floodgateGroupName,
                        floodgateSubgroup,
                        floodgateType
                    }) => {
                        if (floodgateSubgroup.type) {
                            floodgateType = floodgateSubgroup.type;
                            floodgateGroupName = floodgateSubgroup.name;
                        }

                        if (floodgateSubgroup.groups) {
                            floodgateSubgroup.groups.forEach(floodgateSubgroup => {
                                processFloodgateSubgroup({
                                    floodgateGroupName,
                                    floodgateSubgroup,
                                    floodgateType
                                });
                            });
                        } else if (floodgateSubgroup.items) {
                            floodgateSubgroup.items.forEach(item => {
                                const type = item.type || floodgateType,

                                    groupName = item.type || floodgateGroupName || type;

                                let floodgate,
                                    floodgateById = floodgateByTypeAndId[type];

                                if (!floodgateById) {
                                    floodgateById = {};
                                    floodgateByTypeAndId[type] = floodgateById;
                                }

                                floodgate = floodgateById[item.id];

                                if (!floodgate) {
                                    floodgate = {};
                                    floodgateById[item.id] = floodgate;
                                }

                                floodgate.groupName = groupName;
                                floodgate.name = item.name;
                                floodgate.id = item.id;
                                floodgate.type = type;
                            });
                        }
                    };

                processFloodgateSubgroup({
                    floodgateSubgroup: {
                        groups: floodgateGroups
                    }
                });

                state.floodgateByTypeAndId = floodgateByTypeAndId;
            },
            setFloodgateMenus (state, floodgateMenus) {
                state.floodgateMenus = floodgateMenus;
            },
            setFloodgateMessages (state, floodgateMessages) {
                state.floodgateMessages = floodgateMessages;
            },
            setPreviousPathname (state, previousPathname) {
                state.previousPathname = previousPathname;
            },
            setTransferDestinations (state, transferDestinations) {
                state.transferDestinations = transferDestinations;
            },
            setUserAudios (state, userAudios) {
                state.userAudios = userAudios;
            }
        },
        state: () => ({
            activeRequestCount: 0,
            config: window.localStorage && window.localStorage.getItem('config'),
            floodgateByTypeAndId: {},
            floodgateGroups: [],
            floodgateMenus: [],
            floodgateMessages: [],
            previousPathname: null,
            transferDestinations: [],
            userAudios: []
        })
    });

export default _store;
