export default {
    methods: {
        deleteFloodgateMenu (floodgateMenuId) {
            return this.request({
                method: 'DELETE',
                url: `/floodgateMenu/${floodgateMenuId}`
            });
        },
        deleteFloodgateMessage (floodgateMessageId) {
            return this.request({
                method: 'DELETE',
                url: `/floodgateMessage/${floodgateMessageId}`
            });
        },
        deleteFloodgateSchedule (floodgateScheduleId) {
            return this.request({
                method: 'DELETE',
                url: `/floodgateSchedule/${floodgateScheduleId}`
            });
        },
        deleteUserAudio (userAudioId) {
            return this.request({
                method: 'DELETE',
                url: `/userAudio/${userAudioId}`
            });
        },
        disableFloodgateSchedule (floodgateScheduleId) {
            return this.request({
                body: {
                    action: 'disable'
                },
                json: true,
                method: 'POST',
                url: `/floodgateSchedule/${floodgateScheduleId}`
            });
        },
        async download ({
            filename,
            options,
            type
        }) {
            const aElement = document.createElement('a');

            if (filename) {
                aElement.download = filename;
            }

            aElement.href = URL.createObjectURL(new Blob([
                (await this.request({
                    ...options,
                    encoding: null
                })).body
            ], {
                type
            }));

            document.body.appendChild(aElement);
            aElement.click();
            document.body.removeChild(aElement);
        },
        enableFloodgateSchedule (floodgateScheduleId) {
            return this.request({
                body: {
                    action: 'enable'
                },
                json: true,
                method: 'POST',
                url: `/floodgateSchedule/${floodgateScheduleId}`
            });
        },
        async fetchFloodgateMenu (floodgateMenuId) {
            return (await this.request({
                json: true,
                url: `/floodgateMenu/${floodgateMenuId}`
            })).body.floodgateMenu;
        },
        async fetchFloodgateMessage (floodgateMessageId) {
            return (await this.request({
                json: true,
                url: `/floodgateMessage/${floodgateMessageId}`
            })).body.floodgateMessage;
        },
        async fetchFloodgateSchedule (floodgateScheduleId) {
            return (await this.request({
                json: true,
                url: `/floodgateSchedule/${floodgateScheduleId}`
            })).body.floodgateSchedule;
        },
        async fetchReportSections ({
            beginTime,
            endTime
        }) {
            return (await this.request({
                json: true,
                qs: {
                    beginTime,
                    endTime
                },
                url: '/reportSection'
            })).body.reportSections;
        },
        async fetchUserAudio (userAudioId) {
            return (await this.request({
                json: true,
                url: `/userAudio/${userAudioId}`
            })).body.userAudio;
        },
        async fetchVuiLog (vuiId) {
            return (await this.request({
                json: true,
                url: `/vuiLog/${vuiId}`
            })).body.vuiLog;
        },
        refreshData () {
            return Promise.all([
                this.refreshFloodgateGroups(),
                this.refreshFloodgateMenus(),
                this.refreshFloodgateMessages(),
                this.refreshTransferDestinations(),
                this.refreshUserAudios()
            ]);
        },
        refreshFloodgateGroups () {
            return this.$store.dispatch('refreshFloodgateGroups');
        },
        refreshFloodgateMenus () {
            return this.$store.dispatch('refreshFloodgateMenus');
        },
        refreshFloodgateMessages () {
            return this.$store.dispatch('refreshFloodgateMessages');
        },
        refreshTransferDestinations () {
            return this.$store.dispatch('refreshTransferDestinations');
        },
        refreshUserAudios () {
            return this.$store.dispatch('refreshUserAudios');
        },
        request (config) {
            return this.$store.dispatch('request', config);
        },
        saveFloodgateMenu (floodgateMenu) {
            if (floodgateMenu.id) {
                return this.request({
                    body: {
                        action: 'update',
                        ...floodgateMenu
                    },
                    json: true,
                    method: 'POST',
                    url: `/floodgateMenu/${floodgateMenu.id}`
                });
            }

            return this.request({
                body: {
                    action: 'create',
                    ...floodgateMenu
                },
                json: true,
                method: 'POST',
                url: '/floodgateMenu'
            });
        },
        saveFloodgateMessage (floodgateMessage) {
            if (floodgateMessage.id) {
                return this.request({
                    body: {
                        action: 'update',
                        ...floodgateMessage
                    },
                    json: true,
                    method: 'POST',
                    url: `/floodgateMessage/${floodgateMessage.id}`
                });
            }

            return this.request({
                body: {
                    action: 'create',
                    ...floodgateMessage
                },
                json: true,
                method: 'POST',
                url: '/floodgateMessage'
            });
        },
        saveFloodgateSchedule (floodgateSchedule) {
            if (floodgateSchedule.id) {
                return this.request({
                    body: {
                        action: 'update',
                        ...floodgateSchedule
                    },
                    json: true,
                    method: 'POST',
                    url: `/floodgateSchedule/${floodgateSchedule.id}`
                });
            }

            return this.request({
                body: {
                    action: 'create',
                    ...floodgateSchedule
                },
                json: true,
                method: 'POST',
                url: '/floodgateSchedule'
            });
        },
        saveTransferDestination (transferDestination) {
            return this.request({
                body: {
                    action: 'updatePhoneNumber',
                    phoneNumber: transferDestination.phoneNumber
                },
                json: true,
                method: 'POST',
                url: `/transferDestination/${transferDestination.type}/${transferDestination.id}`
            });
        },
        saveUserAudio (userAudio) {
            if (userAudio.id) {
                return this.request({
                    body: {
                        action: 'update',
                        ...userAudio
                    },
                    json: true,
                    method: 'POST',
                    url: `/userAudio/${userAudio.id}`
                });
            }

            return this.request({
                body: {
                    action: 'create',
                    ...userAudio
                },
                json: true,
                method: 'POST',
                url: '/userAudio'
            });
        }
    }
};
