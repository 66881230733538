import './audio-download-icon-component.css';
import _audioTypeToMimeType from './audio-type-to-mime-type.js';
import _dataIoMixin from './data-io-mixin.js';
import _Error from 'isotropic-error';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    data () {
        return {
            audioFormats: [
                'm4a',
                'mp3',
                'opus',
                'raw',
                'ulaw',
                'vorbis',
                'wav',
                'weba'
            ],
            isLoading: false
        };
    },
    methods: {
        getFilename (audioFormat) {
            let filename;

            switch (typeof this.filename) {
                case 'function':
                    filename = this.filename(audioFormat);
                    break;
                case 'object':
                    filename = this.filename && this.filename[audioFormat];
                    break;
                case 'string':
                    if (this.appendAudioFormat) {
                        return `${this.filename}.${audioFormat}`;
                    }

                    return this.filename;
            }

            switch (typeof filename) {
                case 'string':
                case 'undefined':
                    return filename;
            }

            throw _Error({
                details: {
                    filename
                },
                message: 'filename must be a string'
            });
        },
        getOptions (audioFormat) {
            let options;

            switch (typeof this.options) {
                case 'function':
                    options = this.options(audioFormat);
                    break;
                case 'object':
                    options = this.options[audioFormat];

                    if (options) {
                        options = {
                            ...this.options,
                            options,
                            ...this.audioFormats.reduce((audioFormats, audioFormat) => {
                                audioFormats[audioFormat] = void null;
                                return audioFormats;
                            }, {})
                        };
                    } else {
                        options = this.options;
                    }

                    break;
            }

            return {
                ...options,
                ...this.url && {
                    url: this.getUrl(audioFormat)
                } || {}
            };
        },
        getType (audioFormat) {
            let type;

            switch (typeof this.type) {
                case 'function':
                    type = this.type(audioFormat);
                    break;
                case 'object':
                    type = this.type && this.type[audioFormat];
                    break;
                case 'string':
                    return this.type;
            }

            if (typeof type === 'string') {
                return type;
            }

            return 'application/octet-stream';
        },
        getUrl (audioFormat) {
            let url;

            switch (typeof this.url) {
                case 'function':
                    url = this.url(audioFormat);
                    break;
                case 'object':
                    url = this.url && this.url[audioFormat];
                    break;
                case 'string':
                    if (this.appendAudioFormat) {
                        return `${this.url}.${audioFormat}`;
                    }

                    return this.url;
            }

            switch (typeof url) {
                case 'string':
                case 'undefined':
                    return url;
            }

            throw _Error({
                details: {
                    url
                },
                message: 'url must be a string'
            });
        },
        async onClick ({
            audioFormat
        }) {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            try {
                await this.download({
                    filename: this.getFilename(audioFormat),
                    options: this.getOptions(audioFormat),
                    type: this.getType(audioFormat)
                });
            } finally {
                this.isLoading = false;
            }
        }
    },
    mixins: [
        _dataIoMixin
    ],
    name: 'audio-download-icon',
    props: {
        appendAudioFormat: {
            default: true
        },
        filename: {},
        options: {
            default () {
                return {};
            }
        },
        title: {
            default: 'Download this audio file.',
            type: String
        },
        type: {
            default () {
                return {
                    ..._audioTypeToMimeType
                };
            }
        },
        url: {}
    },
    template: `
        <div class="audio-download-icon pure-menu pure-menu-horizontal">
            <ul class="pure-menu-list">
                <li class="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
                    <i :class="isLoading ? 'fa fa-spinner' : 'fa fa-download pointer'" :title="title"></i>
                    <ul class="pure-menu-children">
                        <li class="pure-menu-heading">
                            Select Audio Format
                        </li>
                        <li class="pure-menu-item" v-for="audioFormat in audioFormats" v-on:click="onClick({audioFormat: audioFormat})">
                            <a class="pointer pure-menu-link">
                                {{ audioFormat }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    `
});
