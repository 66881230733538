import _moment from 'moment-timezone';
import _timeZone from '../js/time-zone.js';
import _userDateFormats from './user-date-formats.js';
import _userTimeFormats from './user-time-formats.js';

export default userDateTime => _moment.tz(userDateTime, [
    ..._userTimeFormats.reduce((userDateTimeFormats, userTimeFormat) => {
        _userDateFormats.forEach(userDateFormat => {
            userDateTimeFormats.push(`${userDateFormat} ${userTimeFormat}`);
        });
        return userDateTimeFormats;
    }, []),
    ..._userDateFormats,
    'YYYY-M',
    'MMMM YYYY',
    'MMM YYYY',
    'YYYY',
    'x'
], true, _timeZone);
