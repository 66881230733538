export default () => ({
    button: '',
    center: 'text-align-center',
    column: 'pure-form-aligned',
    contentCenter: 'wide',
    dropdown: {
        caret: '',
        container: '',
        content: '',
        item: '',
        menu: '',
        trigger: ''
    },
    field: '',
    framework: 'purecss',
    groupTr: '',
    inline: 'justify-content pure-control-group wide',
    input: '',
    label: '',
    left: '',
    nomargin: '',
    pagination: {
        active: 'pure-menu-selected',
        get count () {
            return 'text-align-center';
        },
        item: 'pure-menu-item',
        link: 'pure-menu-link',
        list: 'pure-menu-list text-align-center wide',
        get nav () {
            return 'pure-menu pure-menu-horizontal';
        },
        next: '',
        prev: '',
        get wrapper () {
            return '';
        }
    },
    right: 'float-right',
    row: '',
    select: '',
    small: '',
    table: 'pure-table pure-table-striped wide'
});
