import './vui-log-browser-component.css';
import _createUniqueIdsMixin from './create-unique-ids-mixin.js';
import _dataIoMixin from './data-io-mixin.js';
import _DatetimeInputComponent from './datetime-input-component.js';
import _later from 'isotropic-later';
import _MainComponent from './main-component.js';
import _moment from 'moment-timezone';
import _timeZone from '../js/time-zone.js';
import _Vue from 'vue/dist/vue.esm.js';

export default _Vue.extend({
    components: {
        'datetime-input': _DatetimeInputComponent,
        'main-component': _MainComponent
    },
    data () {
        return {
            columns: [
                'id',
                'beginTime',
                'endTime',
                'duration',
                'calledPhoneNumber',
                'callerPhoneNumber',
                'carrierId',
                'carrierType',
                'countyId',
                'hostname',
                'name'
            ],
            durationType: 'seconds',
            filterBeginTimeAfter: '',
            filterBeginTimeBefore: '',
            filterCalledPhoneNumber: '',
            filterCallerPhoneNumber: '',
            filterCarrierId: '',
            filterCarrierType: '',
            filterCountyId: '',
            filterDuration: '',
            filterDurationValue: '',
            filterEndTimeAfter: '',
            filterEndTimeBefore: '',
            filterHostname: '',
            filterId: '',
            filterName: '',
            options: {
                filterable: [
                    'filterBeginTimeAfter',
                    'filterBeginTimeBefore',
                    'filterCalledPhoneNumber',
                    'filterCallerPhoneNumber',
                    'filterCarrierId',
                    'filterCarrierType',
                    'filterCountyId',
                    'filterDuration',
                    'filterEndTimeAfter',
                    'filterEndTimeBefore',
                    'filterHostname',
                    'filterId',
                    'filterName'
                ],
                filterByColumn: true,
                headings: {
                    beginTime: 'Begin Time',
                    calledPhoneNumber: 'Called Phone Number',
                    callerPhoneNumber: 'Caller Phone Number',
                    carrierId: 'Carrier Id',
                    carrierType: 'Carrier Type',
                    countyId: 'County Id',
                    duration: 'Duration',
                    endTime: 'End Time',
                    hostname: 'Hostname',
                    id: 'Id',
                    name: 'Name'
                },
                orderBy: {
                    ascending: false,
                    column: 'beginTime'
                },
                perPage: 100,
                perPageValues: [
                    100,
                    250,
                    500,
                    1000
                ],
                preserveState: true,
                async requestFunction (request) {
                    const query = {
                        page: request.page,
                        pageSize: request.limit
                    };

                    if (request.orderBy) {
                        query[`sortBy[${request.orderBy}]`] = request.ascending ?
                            'asc' :
                            'desc';
                    }

                    if (request.multiSort) {
                        request.multiSort.forEach(sort => {
                            query[`sortBy[${sort.column}]`] = sort.ascending ?
                                'asc' :
                                'desc';
                        });
                    }

                    if (request.query.filterBeginTimeAfter) {
                        if (request.query.filterBeginTimeBefore) {
                            query.beginTime = `${request.query.filterBeginTimeAfter},${request.query.filterBeginTimeBefore}`;
                        } else {
                            query.beginTime = `${request.query.filterBeginTimeAfter},`;
                        }
                    } else if (request.query.filterBeginTimeBefore) {
                        query.beginTime = `,${request.query.filterBeginTimeBefore}`;
                    }

                    if (request.query.filterCalledPhoneNumber) {
                        query.calledPhoneNumber = request.query.filterCalledPhoneNumber;
                    }

                    if (request.query.filterCallerPhoneNumber) {
                        query.callerPhoneNumber = request.query.filterCallerPhoneNumber;
                    }

                    if (request.query.filterCarrierId) {
                        query.carrierId = request.query.filterCarrierId;
                    }

                    if (request.query.filterCarrierType) {
                        query.carrierType = request.query.filterCarrierType;
                    }

                    if (request.query.filterCountyId) {
                        query.countyId = request.query.filterCountyId;
                    }

                    if (request.query.filterDuration) {
                        const [
                            ,
                            prefix = '',
                            number
                        ] = request.query.filterDuration.match(/^((?:[<>]=?)|!)?((?:(?:\d+)?\.\d+)|\d+)$/u);

                        query.duration = `${prefix}${
                            +number * ({
                                hours: 3600000,
                                minutes: 60000,
                                seconds: 1000
                            }[this.durationType] || 1)
                        }`;
                    }

                    if (request.query.filterEndTimeAfter) {
                        if (request.query.filterEndTimeBefore) {
                            query.endTime = `${request.query.filterEndTimeAfter},${request.query.filterEndTimeBefore}`;
                        } else {
                            query.endTime = `${request.query.filterEndTimeAfter},`;
                        }
                    } else if (request.query.filterEndTimeBefore) {
                        query.endTime = `,${request.query.filterEndTimeBefore}`;
                    }

                    if (request.query.filterHostname) {
                        query.hostname = request.query.filterHostname;
                    }

                    if (request.query.filterId) {
                        query.id = request.query.filterId;
                    }

                    if (request.query.filterName) {
                        query.name = request.query.filterName;
                    }

                    {
                        const {
                            count,
                            vuiLogs
                        } = (await this.$parent.$parent.request({
                            json: true,
                            qs: query,
                            url: '/vuiLog'
                        })).body;

                        return {
                            count,
                            data: vuiLogs
                        };
                    }
                },
                serverMultiSorting: true,
                sortIcon: {
                    base: 'fa',
                    down: 'fa-sort-down',
                    is: 'fa-sort',
                    up: 'fa-sort-up'
                },
                texts: {
                    count: 'Showing {from} to {to} of {count} VUI logs|{count} VUI logs|One VUI log',
                    limit: 'Page Size:',
                    noResults: 'No matching VUI logs'
                }
            },
            userColumnsDisplay: []
        };
    },
    filters: {
        formatDuration (value, durationType) {
            return value / ({
                hours: 3600000,
                minutes: 60000,
                seconds: 1000
            }[durationType] || 1);
        },
        formatTimestamp (value) {
            return _moment(value).tz(_timeZone).format('YYYY-MM-DD hh:mm A');
        }
    },
    methods: {
        onDatetimeFilterChange (filter) {
            _later.asap(() => {
                this.$refs.table.setFilter({
                    [filter]: this[filter]
                });
            });
        },
        onDurationTypeChange (event) {
            const newDurationType = event.target.value;

            if (this.filterDuration) {
                const [
                    ,
                    prefix = '',
                    number
                ] = this.filterDuration.match(/^((?:[<>]=?)|!)?((?:(?:\d+)?\.\d+)|\d+)$/u) || [];

                if (number) {
                    this.filterDuration = `${prefix}${
                        +number * ({
                            hours: 3600000,
                            minutes: 60000,
                            seconds: 1000
                        }[this.durationType] || 1) / ({
                            hours: 3600000,
                            minutes: 60000,
                            seconds: 1000
                        }[newDurationType] || 1)
                    }`;
                }
            }

            this.$refs.table.durationType = newDurationType;
            this.durationType = newDurationType;
        },
        onFilterChange (event) {
            const filter = event.target.name.substr(4),
                value = event.target.value;

            this[filter] = value;

            if (!event.target.validity.valid) {
                return;
            }

            this.$refs.table.setFilter({
                [filter]: value
            });
        },
        refresh () {
            this.$refs.table.refresh();
        },
        toggleColumn (column) {
            this.$refs.table.toggleColumn(column);
        }
    },
    mixins: [
        _createUniqueIdsMixin(),
        _dataIoMixin
    ],
    mounted () {
        this.$refs.table.durationType = this.durationType;
        this.$refs.table.userControlsColumns = true;
        this.$refs.table.userColumnsDisplay = this.userColumnsDisplay;

        /* This is a weird hack because vue wasn't rendering the filter inputs
        in the correct places when the columns were all rendered together. */
        [
            'beginTime',
            'calledPhoneNumber',
            'callerPhoneNumber',
            'duration',
            'id'
        ].forEach(column => {
            this.toggleColumn(column);
        });
    },
    name: 'vui-log-browser',
    template: `
        <main-component class="vui-log-browser">
            <h3>VUI Log Browser</h3>
            <v-server-table :columns="columns" name="vuiLogs" :options="options" ref="table" url="">
                <template slot="beforeLimit">
                    <div class="auto-width pure-menu pure-menu-horizontal">
                        <ul class="pure-menu-list">
                            <li class="pure-menu-allow-hover pure-menu-item pure-menu-has-children">
                                <button class="pure-button pure-menu-link" type="button">Columns</button>
                                <ul class="pure-menu-children">
                                    <li class="pure-menu-item" v-for="column in columns">
                                        <label class="auto-width pure-menu-link text-align-left" v-uni-for="'column-display-' + column">
                                            <input :checked="userColumnsDisplay.includes(column)" class="column-checkbox" :disabled="userColumnsDisplay.length === 1 && userColumnsDisplay.includes(column)" type="checkbox" v-on:click="toggleColumn(column)" v-uni-id="'column-display-' + column" />
                                            {{ options.headings[column] }}
                                        </label>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <button class="pure-button" type="button" v-on:click="refresh">
                        <i class="fa fa-refresh"></i>
                        Refresh VUI Logs
                    </button>
                </template>
                <template slot="beginTime" slot-scope="props">{{
                    props.row.beginTime | formatTimestamp
                }}</template>
                <template :duration-type="durationType" slot="duration" slot-scope="props">{{
                    props.row.duration | formatDuration(durationType)
                }}</template>
                <template slot="endTime" slot-scope="props">{{
                    props.row.endTime | formatTimestamp
                }}</template>
                <template slot="filter__beginTime">
                    <datetime-input name="vf__filterBeginTimeAfter" placeholder="Filter After" v-model="filterBeginTimeAfter" v-on:input="onDatetimeFilterChange('filterBeginTimeAfter')" />
                    <datetime-input name="vf__filterBeginTimeBefore" placeholder="Filter Before" v-model="filterBeginTimeBefore" v-on:input="onDatetimeFilterChange('filterBeginTimeBefore')" />
                </template>
                <input class="wide" name="vf__filterCalledPhoneNumber" placeholder="Filter Called Phone Number" slot="filter__calledPhoneNumber" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterCalledPhoneNumber" />
                <input class="wide" name="vf__filterCallerPhoneNumber" placeholder="Filter Caller Phone Number" slot="filter__callerPhoneNumber" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterCallerPhoneNumber" />
                <input class="wide" name="vf__filterCarrierId" placeholder="Filter Carrier Id" slot="filter__carrierId" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterCarrierId" />
                <input class="wide" name="vf__filterCarrierType" placeholder="Filter Carrier Type" slot="filter__carrierType" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterCarrierType" />
                <input class="wide" name="vf__filterCountyId" placeholder="Filter County Id" slot="filter__countyId" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterCountyId" />
                <template slot="filter__duration">
                    <input class="wide" name="vf__filterDuration" pattern="^(?:(?:[<>]=?)|!)?(?:(?:(?:\\d+)?\\.\\d+)|\\d+)$" placeholder="Filter Duration" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterDuration" />
                    <select class="wide" key="durationType" v-on:change="onDurationTypeChange" v-on:click.stop :value="durationType">
                        <option value="milliseconds">
                            Milliseconds
                        </option>
                        <option value="seconds">
                            Seconds
                        </option>
                        <option value="minutes">
                            Minutes
                        </option>
                        <option value="hours">
                            Hours
                        </option>
                    </select>
                </template>
                <template slot="filter__endTime">
                    <datetime-input name="vf__filterEndTimeAfter" placeholder="Filter After" v-model="filterEndTimeAfter" v-on:input="onDatetimeFilterChange('filterEndTimeAfter')" />
                    <datetime-input name="vf__filterEndTimeBefore" placeholder="Filter Before" v-model="filterEndTimeBefore" v-on:input="onDatetimeFilterChange('filterEndTimeBefore')" />
                </template>
                <input class="wide" name="vf__filterHostname" placeholder="Filter Hostname" slot="filter__hostname" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterHostname" />
                <input class="wide" name="vf__filterId" pattern="^!?(?:(?:[0-9a-f\\-]+)|(?:=[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}))$$" placeholder="Filter Id" slot="filter__id" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterId" />
                <input class="wide" name="vf__filterName" placeholder="Filter Name" slot="filter__name" type="text" v-on:change="onFilterChange" v-on:focus="$event.target.select()" :value="filterName" />
                <router-link exact slot="id" slot-scope="props" :to="'/vuiLogBrowser/' + props.row.id">{{ props.row.id }}</router-link>
            </v-server-table>
        </main-component>
    `
});
